.AttractLoop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}

.AttractLoop__visible {
  opacity: 1;
}

.AttractLoop__hidden {
  opacity: 0;
  pointer-events: none;
}

.Logo {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  z-index: 1000;
}