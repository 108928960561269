.Banner {
  width: 100%;
  height: 100%;
  /* background-color: pink; */
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.BannerIn {
  /* z-index: 1; */
}

.BannerOut {
  z-index: 10;
}

.Banner__BackgroundImage {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* opacity should be 0 at the start */
  opacity: 0;
}

.Banner__BackgroundImage__Image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: contain;

}


.Banner__BackgroundImage__Blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  height: 110%;
  width: 110%;
  position: absolute;
  top: 0;
  left: 0;
  margin: -5%;
  object-fit: cover;
}

.Banner__TextArea {
  position: absolute;
  top: 25%;
  left: 10%;
  width: 80%;
  height: 25%;
  /* background-color: red; */
  color: white;
  font-size: 4.5rem;
  font-weight: bolder;
    /* opacity should be 0 at the start */
    opacity: 0;
    text-align: center;
    background: red;
    height: min-content;
    padding: 0.5rem;
}

.LargeText {
  font-size: 3rem;
}

.LargeText {
  font-size: 6rem;
}

.Banner__ButtonArea {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: red; */
  color: black;
    /* opacity should be 0 at the start */
    opacity: 0;
}

.Banner__ButtonArea__Button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.Banner__ButtonArea__Button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
  animation: touch-ripple-pulse 1.5s infinite;
}

.AnimationOpacityFix {
  opacity: 0 !important;
}

.TouchRipple {
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: absolute;
    bottom: 20%;
}

.pulse-circle {
  position: relative;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
  color: white;
  animation: touch-ripple-pulse 1.5s infinite;
}

@keyframes touch-ripple-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}